const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '/api';

export const deletePhotosCall = async (payload) => {

    const response = await fetch(`${REACT_APP_API_URL}/delete`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        single_photo_path:payload,
    })
    });

    if (!response.ok) {
    throw new Error('Network response was not ok');
    }
    return response.json();
};

export const fetchContactUs = async(payload) => {
    const response = await fetch(`${REACT_APP_API_URL}/contact-us`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (response.status >= 400){
        throw new Error("Failed to send email!") 
    }

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};


export const uploadPhotoCall = async(payload) => {

    const response = await fetch(`${REACT_APP_API_URL}/upload`, {  // Replace with your actual API endpoint
        method: 'POST',
        body: payload,
    });

    if (!response.ok) {
        throw new Error('Failed to process the photo, please try again later or try a different photo.');
    }

    return response.json();

};

export const generateNewSizePhotoCall = async(payload) => {
    const response = await fetch(`${REACT_APP_API_URL}/generate-new-size-photo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        throw new Error('Failed to process the photo, please try again later or try a different photo.');
    }

    return response.json();
};