// src/components/BaseLayout.js

import React from 'react';
import Navbar from './Navbar';
import FlashMessage from './FlashMessage';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const BaseLayout = ({ children }) => {
    const { t } = useTranslation();
    return (
        <div>
            <FlashMessage />
            {/* <LanguageSwitcher /> */}
            {window.location.pathname !== '/'
                && window.location.pathname !== '/zh/'
                && window.location.pathname !== '/zh'
                && window.location.pathname !== '/en/'
                && window.location.pathname !== '/en'
                && <Navbar />}
            <div className="upload-container">
                {children}
            </div>
            {/* Backdrop */}
            <div id="backdrop" className="backdrop">
                <div className="spinner-loader"></div>
                <div style={{ color: 'azure' }} className="backdrop-text">
                    {t("processing")}
                </div>
            </div>
            {/* Donate Section */}
            <div className="donate-section">
                <div style={{ margin: '1em 0', color: '#171c1d' }}>
                    <p style={{ maxWidth: '950px' }}>
                    {parse(t('donation'))}
                    </p>
                </div>
            </div>
            {/* Footer */}
            <footer className="footer">
                <div className="footer-content">
                    <p>{t("footer.1")}</p>
                    <p>{t("footer.2")}</p>
                    <p>{t("footer.3")}</p>
                    <p>{t("footer.contact")}: <a href="mailto:help@passportpicturemaker.com">help@passportpicturemaker.com</a></p>
                    <p style={{ color: '#fdb337' }}>&copy; 2024 All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default BaseLayout;
