// src/components/HowThisWorks.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const HowThisWorks = () => {
  const { t } = useTranslation();
  return (
    <div className="example-section">
      <div className="example-title">{t("how-this-works.title")}</div>
      <div className="example-containers">
        <div className="example-container">
          <div className="example-number-text">
            <div className="step-number">- 01 -</div>
            <div className="example-text">{t("how-this-works.step-1")}</div>
          </div>
          <img src="/static/before-man-w-2.jpg" alt="upload icon" id="before-img" />
        </div>
        <div className="example-container">
          <div className="example-number-text">
            <div className="step-number">- 02 -</div>
            <div className="example-text">{t("how-this-works.step-2")}</div>
          </div>
          <img src="/static/middle-man-w-2.jpg" alt="upload icon" id="middle-img" />
        </div>
        <div className="example-container">
          <div className="example-number-text" id="example-number-text">
            <div className="step-number">- 03 -</div>
            <div className="example-text">{t("how-this-works.step-3")}</div>
          </div>
          <img src="/static/after-man-w.jpg" alt="upload icon" id="after-img" />
        </div>
      </div>
    </div>
  );
};

export default HowThisWorks;
