import React from 'react';
import BaseLayout from '../components/BaseLayout';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const About = () => {
    const { t } = useTranslation();
    return (
        <BaseLayout>
            <div className="about-section">
                <div className="banner">
                    <h1>{t("about.title")}</h1>
                </div>
                <div className="about-content">
                    <p>{parse(t("about.content"))}</p>
                </div>
            </div>
        </BaseLayout>
    );
};

export default About;
