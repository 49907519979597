import React, { useEffect, useState } from 'react';
import BaseLayout from '../components/BaseLayout';
import { useNavigate } from 'react-router-dom';
import HowThisWorks from '../components/HowThisWorks';
import Faq from '../components/Faq';
import { useMessage } from "../context/MessageContext";
import { hideLoader, showLoader } from '../utils/load_helpers';
import { uploadPhotoCall } from '../utils/api_helpers';
import { useTranslation } from 'react-i18next';
// const API_URL = 'http://localhost:5001';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '/api';

const UploadPage = ({ photoType }) => {
  const navigate = useNavigate();
  const { showMessage, clearMessage } = useMessage();
  const { t } = useTranslation();
  useEffect(() => {
    clearMessage();
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('photo_type', photoType);

    showLoader();
    uploadPhotoCall(formData).then((data) => {
      navigate('/preview', {
        state: {
          photoType,
          singlePhotoPath: data.single_photo_path,
          photoWidth: data.photo_width,
          photoHeight: data.photo_height,
          headPercent: data.head_percent,
          dpi: data.dpi,
          suggestMsgs: data.suggest_msgs,
        },
      });
    }).catch((e) => {
      showMessage(e.message, "error");
    }).finally(() => {
      hideLoader();
    });
  };
  

  return (
    <BaseLayout>
      <header className="banner short-banner">
        <div className="banner-content">
          <h1>{t(`make-${photoType}`)}</h1>
        </div>
      </header>

      <div className="upload-section" style={{ alignItems: 'center', backgroundColor: '#ffffff' }}>
        <p className="remove-photo-message">{t("delete.message")}</p>
        <form id="upload-form" encType="multipart/form-data">
          <input
            type="file"
            name="file"
            id="actual-btn"
            accept=".jpg, .jpeg, .png, .heif, .heic"
            className="hidden"
            onChange={handleSubmit}
          />
          <input type="hidden" name="photo_type" value={photoType} />
          <label htmlFor="actual-btn" id="actual_upload-button">
            <img src="/static/upload_icon.png" alt="upload icon" width="48px" />
            <span>{t("upload.choose")}</span>
          </label>
        </form>
        <p>{t("upload.message-1")}</p>
        <p>{t("upload.message-2")}</p>
      </div>

      {/* Assuming you have separate components for these sections */}
      <HowThisWorks />

      {(photoType.includes('US') || photoType.includes('China')) && <Faq photoType={photoType}/>}
    
    </BaseLayout>
  );
};

export default UploadPage;
