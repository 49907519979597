import React, { createContext, useState, useContext } from "react";

// Create a context for messages
const MessageContext = createContext();

// Export a custom hook to use the MessageContext
export const useMessage = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState(null);

    const showMessage = (msg, type) => {
        setMessage(msg);
        setMessageType(type);
    };

    const clearMessage = () => {
        setMessage(null);
        setMessageType(null);
    };

    return (
        <MessageContext.Provider
            value={{
                message,
                messageType,
                showMessage,
                clearMessage,
            }}
        >
            {children}
        </MessageContext.Provider>
    );
};
