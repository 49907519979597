import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
// import BlogPage from './pages/Blog'; // Import BlogPage
import UploadPage from './pages/Upload';
import PhotoPreviewPage from './pages/PhotoPreviewPage';
import PhotoResultPage from './pages/PhotoResultPage';
import CustomPhotoPage from './pages/CustomPhotoPage';
import ScrollToTop from './components/ScrollToTop';
// import DigitalPassport from './pages/blogs/DigitalPassport';
// import DualCitizenPassport from './pages/blogs/DualCitizenPassport';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/us-passport-photo" element={<UploadPage photoType="US Passport Photo" />} />
        <Route path="/us-opt-photo" element={<UploadPage photoType="US F1 OPT Photo" />} />
        <Route path="/us-visa-photo" element={<UploadPage photoType="US VISA Photo" />} />
        <Route path="/us-green-card-photo" element={<UploadPage photoType="US Green Card Photo" />} />
        <Route path="/china-visa-photo" element={<UploadPage photoType="China Visa/Passport Photo" />} />
        <Route path="/china-travel-document-photo" element={<UploadPage photoType="China Travel Document Photo" />} />
        <Route path="/schengen-visa-photo" element={<UploadPage photoType="Schengen VISA Photo" />} />
        <Route path="/canada-visa-photo" element={<UploadPage photoType="Canada VISA Photo" />} />
        <Route path="/preview" element={<PhotoPreviewPage />} />
        <Route path="/result" element={<PhotoResultPage />} />
        <Route path="/custom-photo" element={<CustomPhotoPage />} />
        {/* <Route path="/blog" element={<BlogPage />} /> 
        <Route path="/blog/digital-passport" element={<DigitalPassport />} />
        <Route path="/blog/dual-citizen-passport" element={<DualCitizenPassport />} /> */}
        <Route path="*" element={<NotFound />} /> {/* Fallback route for 404 */}
      
        <Route path="/en" element={<Home />} />
        <Route path="/en/about" element={<About />} />
        <Route path="/en/contact" element={<Contact />} />
        <Route path="/en/us-passport-photo" element={<UploadPage photoType="US Passport Photo" />} />
        <Route path="/en/us-opt-photo" element={<UploadPage photoType="US F1 OPT Photo" />} />
        <Route path="/en/us-visa-photo" element={<UploadPage photoType="US VISA Photo" />} />
        <Route path="/en/us-green-card-photo" element={<UploadPage photoType="US Green Card Photo" />} />
        <Route path="/en/china-visa-photo" element={<UploadPage photoType="China Visa/Passport Photo" />} />
        <Route path="/en/china-travel-document-photo" element={<UploadPage photoType="China Travel Document Photo" />} />
        <Route path="/en/schengen-visa-photo" element={<UploadPage photoType="Schengen VISA Photo" />} />
        <Route path="/en/canada-visa-photo" element={<UploadPage photoType="Canada VISA Photo" />} />
        <Route path="/en/preview" element={<PhotoPreviewPage />} />
        <Route path="/en/result" element={<PhotoResultPage />} />
        <Route path="/en/custom-photo" element={<CustomPhotoPage />} />
        {/* <Route path="/en/blog" element={<BlogPage />} />
        <Route path="/en/blog/digital-passport" element={<DigitalPassport />} />
        <Route path="/en/blog/dual-citizen-passport" element={<DualCitizenPassport />} /> */}
        <Route path="/en/*" element={<NotFound />} /> {/* Fallback route for 404 */}
      
        {/* Chinese Routes */}
        <Route path="/zh" element={<Home />} />
        <Route path="/zh/about" element={<About />} />
        <Route path="/zh/contact" element={<Contact />} />
        <Route path="/zh/us-passport-photo" element={<UploadPage photoType="US Passport Photo" />} />
        <Route path="/zh/us-opt-photo" element={<UploadPage photoType="US F1 OPT Photo" />} />
        <Route path="/zh/us-visa-photo" element={<UploadPage photoType="US VISA Photo" />} />
        <Route path="/zh/us-green-card-photo" element={<UploadPage photoType="US Green Card Photo" />} />
        <Route path="/zh/china-visa-photo" element={<UploadPage photoType="China Visa/Passport Photo" />} />
        <Route path="/zh/china-travel-document-photo" element={<UploadPage photoType="China Travel Document Photo" />} />
        <Route path="/zh/schengen-visa-photo" element={<UploadPage photoType="Schengen VISA Photo" />} />
        <Route path="/zh/canada-visa-photo" element={<UploadPage photoType="Canada VISA Photo" />} />
        <Route path="/zh/preview" element={<PhotoPreviewPage />} />
        <Route path="/zh/result" element={<PhotoResultPage />} />
        <Route path="/zh/custom-photo" element={<CustomPhotoPage />} />
        {/* <Route path="/zh/blog" element={<BlogPage />} />
        <Route path="/zh/blog/digital-passport" element={<DigitalPassport />} />
        <Route path="/zh/blog/dual-citizen-passport" element={<DualCitizenPassport />} /> */}
        <Route path="/zh/*" element={<NotFound />} /> {/* Fallback route for 404 in Chinese */}
      </Routes>
    </Router>
  );
};

export default App;
