import React from 'react';

const Ruler = () => {
  return (
    <div className='ruler inch-ruler'>
      <div className='cm' style={{ width: '44%'}}>
        {[...Array(19)].map((_, index) => (
          <div key={index} className='mm'></div>
        ))}
      </div>
      <div className='cm' style={{ width: '44%'}}>
        {[...Array(19)].map((_, index) => (
          <div key={index} className='mm'></div>
        ))}
      </div>
      <div className='cm' style={{ width: '44%'}}></div>
    </div>
  );
};

export default Ruler;
