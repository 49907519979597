import React from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';

const LanguageSwitcher = () => {
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    // if (lng === "en") {
    //   navigate(`/`)
    // } else {
    //   navigate(`/${lng}`);
    // }
  };

  return (
    <span style={{fontSize: "small", color: "#fdb337"}}>
      {currentLanguage.indexOf("en") === 0 && <button onClick={() => changeLanguage('zh')}>中文</button>}
      {currentLanguage.indexOf("zh")=== 0 &&<button onClick={() => changeLanguage('en')}>English</button>}
    </span>
  );
};

export default LanguageSwitcher;
