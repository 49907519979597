import React from 'react';

const Ruler = () => {
  const cmBlocks = Array(5).fill(null); // Create an array with 5 elements for each cm block
  
  return (
    <div className='ruler'>
      {cmBlocks.map((_, index) => (
        <div key={index} className='cm' style={{width: '30%'}}>
          {[...Array(9)].map((_, idx) => (
            <div key={idx} className='mm'></div>
          ))}
        </div>
      ))}
      <div className='cm' style={{width: '30%'}}></div> {/* Last empty cm block */}
    </div>
  );
};

export default Ruler;
