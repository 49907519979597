import React, { useState, useEffect } from 'react';
import BaseLayout from '../components/BaseLayout';
import { useNavigate } from 'react-router-dom';
import { useMessage } from "../context/MessageContext";
import { hideLoader, showLoader } from '../utils/load_helpers';
import { uploadPhotoCall } from '../utils/api_helpers';
import { useTranslation } from 'react-i18next';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '/api';

const CustomPhotoPage = () => {
  const { t } = useTranslation();
  const [photoName, setPhotoName] = useState('');
  const [photoWidth, setPhotoWidth] = useState('');
  const [photoHeight, setPhotoHeight] = useState('');
  const [headPercent, setHeadPercent] = useState('');
  const [dpi, setDpi] = useState('600');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { showMessage, clearMessage } = useMessage();


  useEffect(() => {
    clearMessage();
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    const formData = new FormData();
    formData.append('photo_type', 'Custom Photo');
    formData.append('photo_name', photoName);
    formData.append('photo_width', photoWidth);
    formData.append('photo_height', photoHeight);
    formData.append('head_percent', headPercent);
    formData.append('dpi', dpi);
    formData.append('file', file);
    showLoader();

    uploadPhotoCall(formData).then((data) => {
      navigate('/preview', {
        state: {
          photoType: "Custom Photo",
          singlePhotoPath: data.single_photo_path,
          photoWidth: data.photo_width,
          photoHeight: data.photo_height,
          headPercent: data.head_percent,
          dpi: data.dpi,
          suggestMsgs: data.suggest_msgs,
        },
      });
    }).catch((e) => {
      showMessage(e.message, "error");
    }).finally(() => {
      setLoading(false);
      hideLoader();
    });
  };

  return (
    <BaseLayout>
      <header className="banner short-banner">
        <div className="banner-content">
          <h1>{t("custom.title")}</h1>
        </div>
      </header>

      <div className="customized-upload-section" style={{ alignItems: 'center' }}>
        <p className="remove-photo-message">{t("delete.message")}</p>
        <form id="upload-form" onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="input-group">
            <label htmlFor="photo_name">{t("custom.photo_type")}:</label>
            <input
              className="input-group-item"
              type="text"
              id="photo_name"
              name="photo_name"
              placeholder={t("custom.title_placeholder")}
              value={photoName}
              onChange={(e) => setPhotoName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="photo_width">{t("custom.width")}:</label>
            <input
              className="input-group-item"
              type="number"
              id="photo_width"
              name="photo_width"
              placeholder="e.g. 35"
              min="20"
              max="60"
              value={photoWidth}
              onChange={(e) => setPhotoWidth(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="photo_height">{t("custom.height")}:</label>
            <input
              className="input-group-item"
              type="number"
              id="photo_height"
              name="photo_height"
              placeholder="e.g. 45"
              min="30"
              max="80"
              value={photoHeight}
              onChange={(e) => setPhotoHeight(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="head_percent">{t("custom.head")}:</label>
            <input
              className="input-group-item"
              type="number"
              id="head_percent"
              name="head_percent"
              placeholder="e.g. 75"
              min="50"
              max="90"
              value={headPercent}
              onChange={(e) => setHeadPercent(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="dpi">{t("custom.dpi")}:</label>
            <select
              id="dpi"
              name="dpi"
              value={dpi}
              onChange={(e) => setDpi(e.target.value)}
            >
              <option value="300">300 DPI</option>
              <option value="400">400 DPI</option>
              <option value="600">600 DPI</option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="file">{t("custom.file")}:</label>
            <input
              id="file"
              type="file"
              name="file"
              accept=".jpg, .jpeg, .png, .heif, .heic"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="submit"
              value={t("custom.submit")}
              disabled={loading}
            />
          </div>
        </form>
        {/* {loading && <p>Loading...</p>} */}
        {/* {error && <p>Error: {error}</p>} */}
        <p>{t("upload.message-1")}</p>
        <p>{t("upload.message-2")}</p>
      </div>
    </BaseLayout>
  );
};

export default CustomPhotoPage;
