import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BaseLayout from '../components/BaseLayout';
import Ruler from '../components/Ruler';
import InchRuler from '../components/InchRuler';
import { deletePhotosCall, generateNewSizePhotoCall } from '../utils/api_helpers';
import { useMessage } from "../context/MessageContext";
import { hideImageLoader, showImageLoader } from '../utils/load_helpers';
import { useTranslation } from 'react-i18next';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '/api';

const PhotoPreviewPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const {
    photoType,
    singlePhotoPath,
    photoWidth,
    photoHeight,
    headPercent,
    dpi,
    suggestMsgs,
  } = location.state || {};
  // Default values
  const defaultSettings = {
    bodySize: 1,
    upOrDown: 1,
    brightness: 0,
    contrast: 0,
  };

  // Initialize state with values from location.state or default values
  const [settings, setSettings] = useState({
    bodySize: location.state?.bodySize || defaultSettings.bodySize,
    upOrDown: location.state?.upOrDown || defaultSettings.upOrDown,
    brightness: location.state?.brightness || defaultSettings.brightness,
    contrast: location.state?.contrast || defaultSettings.contrast,
    singlePhotoPath: location.state?.singlePhotoPath,
  });


  const handleSliderChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setSettings(prev => ({ ...prev, [name]: value }));
    setShouldPost(true);
  };

  const [shouldPost, setShouldPost] = useState(false);
  // const [success, setSuccess] = useState(null);
  const { showMessage } = useMessage();
  // Use useEffect to trigger the POST request after settings state is updated
  useEffect(() => {
    if (shouldPost) {
      // Make the POST request here using the updated settings
      submitRetryForm();
      setShouldPost(false); // Reset to avoid repeated POST requests
    }
  }, [settings, shouldPost]);

  const submitRetryForm = async () => {
    const backdropClass = photoType.includes("China ") ? "ch-show" : "us-show";
    showImageLoader(backdropClass);

    const payload = {
      photo_type: photoType,
          single_photo_path: singlePhotoPath,
          body_size: settings['bodySize'],
          up_or_down: settings["upOrDown"],
          brightness: settings["brightness"],
          contrast: settings["contrast"],
          photo_width: photoWidth,
          photo_height: photoHeight,
          head_percent: headPercent,
          dpi: dpi,
    };

    generateNewSizePhotoCall(payload).then(() => {
      setSettings(prevSettings => ({
        ...prevSettings,
        singlePhotoPath:`${singlePhotoPath}&timestamp=${new Date().getTime()}`,
      }));
    }).catch((e) => {
      showMessage(e.message, "error");
    }).finally(() => {
      hideImageLoader(backdropClass);
    });
  };

  const resetSettings = (e) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      ...defaultSettings,
    }));
    setShouldPost(true);
  };

  const navigate = useNavigate();

  const handleDownloadClick = () => {
    // Replace '/results' with the actual path you want to navigate to
    navigate('/result', { state: { singlePhotoPath: settings.singlePhotoPath, photoType: photoType } });
    // navigate.push({
    //   pathname: '/results',
    //   state: { singlePhotoPath: settings.singlePhotoPath, photoType: photoType },
    // });
  };
  const deletePhotos = () => {
    deletePhotosCall(settings["singlePhotoPath"]).then(() => {
      showMessage("Your photo was successfully removed!", "success");
      navigate('/');
    });
  }

  return (
    <BaseLayout>
      <div className="banner short-banner">
        <h1>{t("preview.title")}</h1>
      </div>

      <div className="result-section" style={{ backgroundColor: 'white' }}>
        <p className="remove-photo-message">
          {t("delete.message")}
          <button onClick={deletePhotos}>{t("delete.remove")}</button>
        </p>

        <div className="photo-display">
          <div className="us-passport-photo">
            {/* Conditional rendering based on photoType */}
            {photoType.includes('China ') ? (
              <>
                <div className="ruler-container-portrait">
                  <Ruler />
                </div>
                <div className="photo-container">
                  <img src={`/api/show?single_photo_path=${settings["singlePhotoPath"]}`} alt="Passport Photo" width="168px" />
                  <div className="image-backdrop" id="image-backdrop">
                    <div className="image-loader"></div>
                  </div>
                </div>
                <div className="ruler-container-landscape">
                  <Ruler />
                </div>
              </>
            ) : photoType.includes('US ') ? (
              <>
                <div className="ruler-container-portrait inch-ruler-container-portrait">
                  <InchRuler />
                </div>
                <div className="photo-container">
                  <img src={`/api/show?single_photo_path=${settings["singlePhotoPath"]}`} alt="Passport Photo" width="256px" />
                  <div className="image-backdrop" id="image-backdrop">
                    <div className="image-loader"></div>
                  </div>
                </div>
                <div className="ruler-container-landscape inch-ruler-container-landscape">
                  <InchRuler />
                </div>
              </>
            ) : (
              <>
                {!settings["singlePhotoPath"].includes('custom_photo') && (
                  <div className="ruler-container-portrait">
                    <Ruler />
                  </div>
                )}
                <div className="photo-container">
                  <img src={`/api/show?single_photo_path=${settings["singlePhotoPath"]}`} alt="Passport Photo" width="177px" style={{ marginTop: '17px' }} />
                  <div className="image-backdrop" id="image-backdrop">
                    <div className="image-loader"></div>
                  </div>
                </div>
                {!settings["singlePhotoPath"].includes('custom_photo') && (
                  <div className="ruler-container-landscape">
                    <Ruler />
                  </div>
                )}
              </>
            )}
          </div>

          <form id="retry-photo-form" encType="multipart/form-data">
            {/* Resize and adjustment controls */}
            {[{name: 'bodySize', max: 1.5, min: 0.5, step: 0.05},
            {name: 'upOrDown', max: 1.5, min: 0.5, step: 0.05},
            {name: 'brightness', max: 50, min: -50, step: 2},
            {name: 'contrast', max: 50, min: -50, step: 2}].map((config) => (
              <div className="resize-tool" key={config["name"]}>
                <label htmlFor={`${config["name"]}_slider`}>{t(`preview.${config["name"]}`)}:</label>
                <button className="button" type="button" name={`${config["name"]}-minus-button`} onClick={() => handleSliderChange({ target: { name: config["name"], value: settings[config["name"]] - config["step"] } })}>
                  <i className="fas fa-minus"></i>
                </button>
                <input
                  type="range"
                  id={`${config["name"]}_slider`}
                  name={config["name"]}
                  min={config["min"]}
                  max={config["max"]}
                  step={config["step"]}
                  value={settings[config["name"]]}
                  onChange={handleSliderChange}
                />
                <button className="button" type="button" name={`${config["name"]}-plus-button`}  onClick={() => handleSliderChange({ target: { name: config["name"], value: settings[config["name"]] + config["step"] } })}>
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            ))}
            <button type="button" style={{ marginTop: '1em' }} onClick={resetSettings}>{t("preview.reset")}</button>
          </form>
        </div>

        {suggestMsgs && Object.keys(suggestMsgs).length > 0 && (
          <div className="suggest-msg">
              {suggestMsgs.map((msg, index) => (
                <div className="suggest-msg-text" key={index}>
                  <i className="start-icon fa fa-exclamation-triangle" style={{fontSize: '1.5em'}}></i>
                  <span>{t(`alert.${msg}`)}</span>
                </div>
                // <li className="delete-photo-message" key={index}>{msg}</li>
              ))}
          </div>
        )}

        <div className="photo-modify">
          <div className="proceed-to-download" style={{ margin: '1em 0' }}>
            <button onClick={handleDownloadClick}>{t("preview.download")}</button>
            {/* <a href={`/api/result?single_photo_path=${settings["singlePhotoPath"]}&photo_type=${photoType}`}>
              <button>Proceed to Download</button>
            </a> */}
          </div>
        </div>
        </div>
        

      {photoType.includes('China ') && (
        <div className="sample-section">
          <div className="example-title">{t("preview.requirement")}</div>
          <img id="example-photo" src="/static/china-visa-example.png" alt="Passport Photo" width="290px" />
        </div>
      )}

      {photoType.includes('US ') && (
        <div className="sample-section">
          <div className="example-title">{t("preview.requirement")}</div>
          <img id="example-photo" src="/static/us-passport-photo.jpeg" alt="Passport Photo" width="600px" />
        </div>
      )}
    </BaseLayout>
  );
};

export default PhotoPreviewPage;
