import React, { useEffect, useState } from 'react';
import usFaq from '../data/usFaq.json';
import chinaFaq from '../data/chinaFaq.json';
import { useTranslation } from 'react-i18next';

const Faq = (props) => {
    const { t } = useTranslation();
    const [country, setCountry] = useState('');
    const [data,setData] = useState([]);

    const toggleAccordion = (event) => {
        const button = event.currentTarget;
        const expanded = button.getAttribute('aria-expanded') === 'true' || false;
        button.setAttribute('aria-expanded', !expanded);
        button.nextElementSibling.style.display = !expanded ? 'block' : 'none';
    };

    useEffect(() => {
        setCountry(props.photoType.includes('US')? 'US' : 'China');
        setData(props.photoType.includes('US')? usFaq : chinaFaq);
    }, [props.photoType]);

  return (
    <div className="faq-section">
        <div className="faq-title">{t("faq.title")}</div>
        <h1 style={{ textAlign: 'center', color: '#fdb337' }}>
        {t(`faq.Frequently Asked Questions about ${country} Passport/VISA Photos`)}
        </h1>
        <div className="faq-container">
            {data.map(function(faq) {
            return (
            <div className="accordion" key={faq.question}>
                <div className="accordion-item">
                <button
                    id="accordion-button-2"
                    aria-expanded="false"
                    onClick={toggleAccordion}
                >
                    <span className="accordion-title">{faq.question}</span>
                    <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                    <p>{faq.anwser}</p>
                </div>
            </div>
            </div>
            );
            })}
        </div>
    </div>
  );
};

export default Faq;
