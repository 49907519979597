export const showLoader = () => {
    document.getElementById('backdrop').classList.add('show');
};

export const hideLoader = () => {
    document.getElementById('backdrop').classList.remove('show');
};

export const showImageLoader = (backdropClass) => {
    document.getElementById('image-backdrop').classList.add(backdropClass);
};

export const hideImageLoader = (backdropClass) => {
    document.getElementById('image-backdrop').classList.remove(backdropClass);
};