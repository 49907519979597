// src/components/NotesSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const NotesSection = () => {
  const { t } = useTranslation();
  return (
    <div className="how-to-section">
      <ol className="alternating-colors">
        <li>
          <p>{t("note-section.1")}</p>
        </li>
        <li>
          <p>{t("note-section.2")}</p>
        </li>
        <li>
          <p>{t("note-section.3")}</p>
        </li>
        <li>
          <p>{t("note-section.4")}</p>
        </li>
        <li>
          <p>{t("note-section.5")}</p>
        </li>
        <li>
          <p>{parse(t("note-section.6"))}</p>
        </li>
      </ol>
    </div>
  );
};

export default NotesSection;
