import React, { useState } from 'react';
import { useMessage } from "../context/MessageContext";
import { useTranslation } from 'react-i18next';

const FlashMessage = () => {
  // const [visible, setVisible] = useState(true);
  const { message, messageType, clearMessage } = useMessage();
  const { t } = useTranslation();
  // const hideMsg = () => {
  //   setVisible(false);
  // };

  // if (!visible || !message) return null;

  const bgColor = messageType === 'error' ? 'bg-red-100' : 'bg-green-100';
  const borderColor = messageType === 'error' ? 'border-red-400' : 'border-green-400';
  const textColor = messageType === 'error' ? 'text-red-700' : 'text-green-700';
  const iconColor = messageType === 'error' ? 'text-red-500' : 'text-green-500';
  if (!message) {
    return null;
  }
  return (
    <div
      id="flash_message"
      className={`${bgColor} ${borderColor} ${textColor} px-4 py-3 rounded relative`}
      role="alert"
      style={{display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              columnGap: '1em'}}
    >
      {messageType !== 'error' ? <i className="start-icon far fa-check-circle" style={{fontSize: '1.2em'}}
      ></i> :  <i className="start-icon fa fa-exclamation-triangle" style={{fontSize: '1.2em'}}></i> }

        <span key="message" className="block sm:inline">
            {t(message)}
        </span>
        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
            onClick={clearMessage}
            className={`fill-current h-6 w-6 ${iconColor}`}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
};

export default FlashMessage;
