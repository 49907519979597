// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
const Navbar = () => {
  const { t } = useTranslation();
  return (
    <div className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-icon">
          <img src="/static/smart_pototo.PNG" alt="Passport Photo" width="600px" />
          <span style={{fontFamily: 'FontAwesome', fontSize: 'small'}}>Smart Pototo</span>
        </Link>
      </div>
      <div className="navbar-right">
        <Link to="/" style={{ marginRight: '1rem' }}>{t("navbar.home")}</Link>
        {/* <Link to="/blog" style={{ marginRight: '1rem' }}>{t("navbar.blog")}</Link> */}
        <Link to="/contact" style={{ marginRight: '1rem' }}>{t("navbar.contact")}</Link>
        <Link to="/about" style={{ marginRight: '1rem' }}>{t("navbar.about-us")}</Link>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default Navbar;
