import React from 'react';
import BaseLayout from '../components/BaseLayout';
import Navbar from '../components/Navbar';
import HowThisWorks from '../components/HowThisWorks';
import WhyChooseUs from '../components/WhyChooseUs';
import NotesSection from '../components/NotesSection';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const Home = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <BaseLayout>
            <header className="banner">
                <div className="banner-content">
                    <p style={{ fontFamily: 'FontAwesome', fontSize: 'small'}}>Smart Pototo</p>
                    <h1>{t("banner.title")}</h1>
                    <p>{t("banner.subTitle")}</p>
                    <div style={{ marginTop: '3em' }}>
                        <a href="#upload-section" className="make-your-photo-button">{t("banner.button")}</a>
                    </div>
                </div>
            </header>
            <Navbar />
            <HowThisWorks />
            <WhyChooseUs />
            <div className="upload-section" id="upload-section">
                <div className="example-title" style={{ marginBottom: '1em', marginLeft: '-1.5em' }}>
                    {t("create-photo.title")}
                </div>
                <div className="buttons-how-to">
                    <div>
                        <div className="row-buttons">
                            <button className="upload-button" type='button' onClick={()=> {navigate("/us-passport-photo")}}>{t("create-photo.type.us-passport.name")} <span className="size-text">{t("create-photo.type.us-passport.size")}</span></button>
                            <button className="upload-button" type='button' onClick={()=> {navigate("/us-visa-photo")}}>{t("create-photo.type.us-visa.name")} <span className="size-text">{t("create-photo.type.us-visa.size")}</span></button>
                            <button className="upload-button" type='button' onClick={()=> {navigate("/us-opt-photo")}}>{t("create-photo.type.us-opt.name")} <span className="size-text">{t("create-photo.type.us-opt.size")}</span></button>
                            <button className="upload-button" type='button' onClick={()=> {navigate("/us-green-card-photo")}}>{t("create-photo.type.us-green-card.name")} <span className="size-text">{t("create-photo.type.us-green-card.size")}</span></button>

                            <button className="upload-button" type='button' onClick={()=> {navigate("/china-visa-photo")}}>{t("create-photo.type.zh-visa.name")} <span className="size-text">{t("create-photo.type.zh-visa.size")}</span></button>
                            <button className="upload-button" type='button' onClick={()=> {navigate("/china-travel-document-photo")}}>{t("create-photo.type.zh-travel.name")} <span className="size-text">{t("create-photo.type.zh-travel.size")}</span></button>
                            <button className="upload-button" type='button' onClick={()=> {navigate("/schengen-visa-photo")}}>{t("create-photo.type.schengen.name")} <span className="size-text">{t("create-photo.type.schengen.size")}</span></button>
                            <button className="upload-button" type='button' onClick={()=> {navigate("/canada-visa-photo")}}>{t("create-photo.type.canada-visa.name")} <span className="size-text">{t("create-photo.type.canada-visa.size")}</span></button>
                            <button className="upload-button" id="custom-upload-button" type='button' onClick={()=> {navigate("/custom-photo")}}>{t("create-photo.type.custom.name")} <span className="size-text">{t("create-photo.type.custom.size")}</span></button>
                        </div>
                    </div>
                    <div className='how-to-notes'><NotesSection /></div>
                </div>
            </div>
        </BaseLayout>
    );
};

export default Home;
