import React, { useState, useEffect } from 'react';
import BaseLayout from '../components/BaseLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { deletePhotosCall } from '../utils/api_helpers';
import { useMessage } from "../context/MessageContext";
import { useTranslation } from 'react-i18next';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '/api';

const PhotoResultPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { singlePhotoPath, photoType } = location.state || {};

  // State to manage the API response and loading state
  const [photoData, setPhotoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Make API request when the component loads
  useEffect(() => {
    const fetchPhotoData = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_URL}/result`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            single_photo_path:singlePhotoPath,
            photo_type: photoType,
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPhotoData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // Trigger the API request if singlePhotoPath and photoType are available
    if (singlePhotoPath && photoType) {
      fetchPhotoData();
    }
  }, []);

  const deletePhotos = () => {
    const response = deletePhotosCall(singlePhotoPath)
    showMessage("Your photo was successfully removed!", "success");
    navigate('/')
  }
  // Destructure photoData safely
  const single_photo_path = photoData?.single_photo_path;
  const multiple_photos_path = photoData?.multiple_photos_path;
  // Render loading text, error message, or the API response message
  let content = ""
  if (loading) {
    content = <div>Loading...</div>;
  } else if (error) {
    content = <div>Error: {error}</div>;
  } else if (!photoData) {
    content = <div>No data available</div>; // Handle case where no data is returned
  } else {
    content = (
      <div className="result-section">
        <p className="remove-photo-message">
          {t("delete.message")}
          <button onClick={deletePhotos}>{t("delete.remove")}</button>
        </p>

        <div className="us-passport-photo">
          <img src={`/api/show?single_photo_path=${single_photo_path}`} alt="Passport Photo" width="250px" />
          <br />
          <a href={`/api/download?single_photo_path=${single_photo_path}`}>
            <button>{t("result.download")}</button>
          </a>
          <br />
        </div>

        <div className="four-six-photo">
          <p>{t("result.4x6")}</p>
          <img src={`/api/show?single_photo_path=${multiple_photos_path}`} alt="Passport Photo" width="600px" />
          <br />
          <a href={`/api/download?single_photo_path=${multiple_photos_path}`}>
            <button>{t("result.download")}</button>
          </a>
          <br />
        </div>
      </div>
    );
  }

    
  return (
    <BaseLayout>
      <div className="banner short-banner">
        <h1>{t("result.title")}</h1>
      </div>
      {content}
    </BaseLayout>
  );
};

export default PhotoResultPage;
