import React, { useState } from 'react';
import { showLoader, hideLoader } from '../utils/load_helpers';
import { fetchContactUs } from '../utils/api_helpers';
import BaseLayout from '../components/BaseLayout';
import { useMessage } from "../context/MessageContext";
import { useTranslation } from 'react-i18next';


const ContactUs = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        subject: '',
        fromEmail: '',
        comment: '',
    });
    const { showMessage } = useMessage();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        showLoader();

        fetchContactUs(formData).then((data) => {
            showMessage(data.message, "success");
            setFormData({
                subject: '',
                fromEmail: '',
                comment: '',
            })
            ;
        }).catch((e) => {
            showMessage(e.message, "error");
        }).finally(() => {
            hideLoader();
            window.scrollTo(0, 0);
        });    
    };

    return (
        <BaseLayout>
            <header className="banner short-banner">
                <div className="banner-content">
                    <h1>{t("contact.title")}</h1>
                </div>
            </header>
            <div className="customized-upload-section" style={{ alignItems: 'center' }}>
                <form id="upload-form" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="subject">{t("contact.subject")}:<span style={{color:"red"}}>*</span></label>
                        <input
                            className="input-group-item"
                            type="text"
                            name="subject"
                            placeholder={t("contact.subject_placeholder")}
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="fromEmail">{t("contact.email")}:</label>
                        <input
                            className="input-group-item"
                            type="email"
                            name="fromEmail"
                            placeholder={t("contact.email_placeholder")}
                            value={formData.fromEmail}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="comment">{t("contact.comment")}:<span style={{color:"red"}}>*</span></label>
                        <textarea
                            className="input-group-item"
                            name="comment"
                            rows="4"
                            cols="50"
                            value={formData.comment}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <div className="input-group">
                        <input type="submit" value={t("contact.submit")} />
                    </div>
                </form>
            </div>
        </BaseLayout>
    );
};

export default ContactUs;
