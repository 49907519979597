// src/components/WhyChooseUs.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const WhyChooseUs = () => {
  const { t } = useTranslation();
  return (
    <div className="why-choose-us">
      <div className="example-title" style={{color: '#092b42'}}>{t("why-choose-us.title")}</div>
      <div className="features">
        <div className="feature">
          <i className="fas fa-cog"></i>
          <div>
            <h2>{t("why-choose-us.cost.name")}</h2>
            <p>{t("why-choose-us.cost.desc")}</p>
          </div>
        </div>
        <div className="feature">
          <i className="fas fa-star"></i>
          <div>
            <h2>{t("why-choose-us.fast.name")}</h2>
            <p>{t("why-choose-us.fast.desc")}</p>
          </div>
        </div>
        <div className="feature">
          <i className="fas fa-lightbulb"></i>
          <div>
            <h2>{t("why-choose-us.shadow.name")}</h2>
            <p>{t("why-choose-us.shadow.desc")}</p>
          </div>
        </div>
        <div className="feature">
          <i className="fas fa-atom"></i>
          <div>
            <h2>{t("why-choose-us.size.name")}</h2>
            <p>{t("why-choose-us.size.desc")}</p>
          </div>
        </div>
        <div className="feature">
          <i className="fas fa-camera"></i>
          <div>
            <h2>{t("why-choose-us.adjust.name")}</h2>
            <p>{t("why-choose-us.adjust.desc")}</p>
          </div>
        </div>
        <div className="feature">
          <i className="fas fa-map-marker-alt"></i>
          <div>
            <h2>{t("why-choose-us.convenient.name")}</h2>
            <p>{t("why-choose-us.convenient.desc")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
